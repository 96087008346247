import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
// import "./table.css";
import DataGrid, {
    Scrolling,
    Paging,
    Column,
} from "devextreme-react/data-grid";
import { CallGETAPI } from "../../helper/API";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import TableSkeleton from "../../pages/accounts/skeleton/table/TableSkeleton";
import New from '../../img/New.png';

export default function UserDocuments(props) {
    // const { accountId } = useParams();
    const navigate = useNavigate();
    const [documentData, setDocumentData] = useState([])
    const [loading, setLoading] = useState(true);

    const getDateTime = (e) => {
        return moment(e?.value).format("DD-MM-YYYY hh:mm A");
    };

    let is_document_details = false

    let permissions = localStorage.getItem('permissions')
    let permissionsArr = permissions.split(',')
    if (permissionsArr.includes('document-details')) {
        is_document_details = true
    }

    const renderLink = (e) => {
        let id = e?.data?.document_id;
        return <Link to={is_document_details ? `/account-document-details/${id}` : ''}>{e?.value}</Link>;
    };

    const redirectUrl = '';

    const fetchData = async () => {
        const response = await CallGETAPI("get-all-documents/", props.account_id);
        if (response?.status) {
            setLoading(false)
            setDocumentData(response?.data?.data?.allAccountDocuments);
        }
    };

    console.log('documentData', documentData);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="relative mb-5">

            {loading && (
                <div className='' style={{ width: '100%', marginTop: "4%" }}>
                    <TableSkeleton />
                </div>
            )}

            {!loading && (
                <>
                    <div style={{ display: 'flex', gap: '10%', width: '100%', justifyContent: 'right', marginTop: '1%' }}>

                        {props.privileges.includes('new-document') && (
                            <Link style={{ textDecoration: 'none' }} to={'/account/sites/new/' + 'accountId'}>
                                <img src={New} />
                                New
                            </Link>
                        )}

                    </div>
                    {documentData && (
                        <>
                            {/* heading */}
                            <Box className="text-left pt-3 pb-1">
                                <h4 className="heading">{props?.siteName} CheckName</h4>
                            </Box>

                            {/* data grid table */}

                            <div className="data-table pb-3">
                                <DataGrid
                                    // dataSource={props?.documentData ? props?.documentData : []}
                                    dataSource={documentData}
                                    // height={250}
                                    keyExpr="document_id"
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={false}
                                    rowAlternationEnabled={true}
                                >
                                    <Column
                                        dataField="parent_document_name"
                                        caption="Document Name"
                                        cssClass="column-header"
                                        cellRender={renderLink}
                                    />
                                    <Column dataField="comment" cssClass="column-header" />
                                    <Column dataField="related_to" cssClass="column-header" />
                                    <Column
                                        dataField="uploaded_by_name"
                                        cssClass="column-header"
                                        caption="Uploaded by"
                                    />
                                    <Column
                                        dataField="createdAt"
                                        cssClass="column-header"
                                        caption={"Uploaded Date"}
                                        cellRender={getDateTime}
                                    />

                                    <Scrolling columnRenderingMode="virtual" />
                                    <Paging enabled={false} />
                                </DataGrid>
                            </div>
                        </>
                    )}

                    {documentData && (
                        <>
                            {/* heading */}
                            <Box className="text-left pt-3 pb-1 d-flex">
                                <h4 className="heading">Account</h4>
                            </Box>

                            {/* data grid table */}

                            <div className="data-table pb-3">
                                <DataGrid
                                    dataSource={documentData}
                                    // height={250}
                                    keyExpr="document_id"
                                    showColumnLines={true}
                                    showRowLines={true}
                                    showBorders={false}
                                    rowAlternationEnabled={true}
                                >
                                    <Column
                                        dataField="parent_document_name"
                                        caption="Document Name"
                                        cssClass="column-header"
                                        cellRender={renderLink}
                                    />
                                    <Column dataField="comment" cssClass="column-header" />
                                    <Column dataField="related_to" cssClass="column-header" />
                                    <Column
                                        dataField="uploaded_by_name"
                                        cssClass="column-header"
                                        caption="Uploaded by"
                                    />
                                    <Column
                                        dataField="createdAt"
                                        cssClass="column-header"
                                        caption={"Uploaded Date"}
                                        cellRender={getDateTime}
                                    />

                                    <Scrolling columnRenderingMode="virtual" />
                                    <Paging enabled={false} />
                                </DataGrid>
                            </div>
                        </>
                    )}

                    {documentData &&
                        documentData?.allGroup &&
                        documentData?.allGroup?.map((item, i) => (
                            <div key={i}>
                                <Box className="text-left pt-3 pb-1">
                                    <h4 className="heading">{item.site_name}</h4>
                                </Box>

                                {/* data grid table */}

                                <div className="data-table pb-3">
                                    <DataGrid
                                        // dataSource={item.response ? item.response : []}
                                        dataSource={documentData}
                                        // height={250}
                                        keyExpr="document_id"
                                        showColumnLines={true}
                                        showRowLines={true}
                                        showBorders={false}
                                        rowAlternationEnabled={true}
                                    >
                                        <Column
                                            dataField="parent_document_name"
                                            caption="Document Name"
                                            cssClass="column-header"
                                            cellRender={renderLink}
                                        />
                                        <Column dataField="comment" cssClass="column-header" />
                                        <Column dataField="related_to" cssClass="column-header" />
                                        <Column
                                            dataField="uploaded_by_name"
                                            cssClass="column-header"
                                            caption="Uploaded by"
                                        />
                                        <Column
                                            dataField="createdAt"
                                            cssClass="column-header"
                                            caption={"Uploaded Date"}
                                            cellRender={getDateTime}
                                        />

                                        <Scrolling columnRenderingMode="virtual" />
                                        <Paging enabled={false} />
                                    </DataGrid>
                                </div>
                            </div>
                        ))}
                    <div></div>
                </>)}
        </div>
    );
}
