import React, { useEffect, useState } from "react";
import { Form, Button as BButton, Button as BsButton } from "react-bootstrap";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Person2Icon from "@mui/icons-material/Person2";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { CallGETAPI } from "../../helper/API";
import "../../../src/global.css";
import { removeFilterData as removeListingFilter, removePayloadData as removeListingFilterPayload } from "../../redux/slices/AccountListFilter";
import { removeFilterData as removeEquipmentFilter, removePayloadData as removeEquipmentFilterPayload } from "../../redux/slices/AccountDetailsEquipmentFilter";
import { useDispatch } from "react-redux";
import ReportsModel from "../modals/ReportsModel";
import SwitchUserModel from "../modals/SwitchUserModel";
import AdminReportsModel from "../modals/AdminReportModel";

export default function SearchForm(user) {
  const navigat = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const profile = JSON.parse(localStorage.getItem("ross-profile"));
  const [contactId, setContactId] = useState(null);
  const [rossProfile, setRossProfile] = useState([]);
  const [openReports, setOpenReports] = useState(false);
  const [adminOpenReports, setAdminOpenReports] = useState(false);
  const [openSwitchUser, setOpenSwitchUser] = useState(false);
  const dispatch = useDispatch();
  const [privileges, setPrivileges] = useState([]);

  const fetchPrivilege = async () => {
    let response = await CallGETAPI("auth/priviledge");
    if (response.status && response?.data?.permission) {
      let permission = response?.data?.permission;
      setPrivileges(permission);
      
      // localStorage.setItem('permissions', permission);
      // const permissionsArray = permission;
      // setPermissionArray(permission)
    }
  };

  useEffect(() => {
    fetchPrivilege();
  }, []);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
 

  const handleLogout = () => {
    sessionStorage.removeItem("ross_token");
    sessionStorage.removeItem("ross_rtoken");
    localStorage.removeItem("ross_token");
    localStorage.removeItem("ross-profile");
    dispatch(removeListingFilter());
    dispatch(removeListingFilterPayload());
    dispatch(removeEquipmentFilter());
    dispatch(removeEquipmentFilterPayload());
    navigat("/");
  };

  useEffect(() => {
    let profile = JSON.parse(localStorage.getItem("ross-profile"));
    setRossProfile(profile);
    if (profile) {
      setContactId(profile?.contact_id);
    }
  }, []);

  return (
    <>
      <div className="Navbar-Search" style={{position:"relative", width: "100%" }}>
        {/* {Number(user?.user?.user_type) == 0 ? (<>  
                <div className='d-flex mx-auto Search-button'>
                    <Form.Control
                        type="search"
                        placeholder="Search"
                        // className="me-2"
                        aria-label="Search"
                    />
                    <button className="btn btn-search text-white p-0" variant="outline-success">
                   <SearchIcon /> 
                    </button>
                </div>
                </>) : ""
                 }  */}

        <div
          className="d-flex align-items-right NavbarUserIconDiv"
          onClick={handleClick}
          style={{position:"relative", cursor: "pointer" }}
        >
          <Person2Icon sx={{ color: "white" }} fontSize="large" />

          <div style={{ color: "white" }} className="NavbarUserEmailDiv">
            <div> {profile?.name}</div>
            <div className="Email">{profile?.email}</div>
          </div>

          <ArrowDropDownIcon
            className="ArrowIcon"
            sx={{ color: "white" }}
            fontSize="large"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          />
        </div>
      </div>

      <Menu
        className="user-dropdown"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{ style: {position:"absolute", backgroundColor: "black" } }} // Set the background color to black
      >
        {rossProfile?.user_type > 1 ? (
          ""
        ) : (
          <>
            <MenuItem onClick={handleClose}>Admin</MenuItem>
          </>
        )}
        {rossProfile?.user_type > 1 ? (
          <>
            {" "}
            <MenuItem
              onClick={() => {
                setOpenReports(true);
                handleClose();
              }}
            >
              Reports
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              onClick={() => {
                setAdminOpenReports(true);
                handleClose();
              }}
            >
              Reports
            </MenuItem>
          </>
        )}
        <MenuItem onClick={""}>Profile</MenuItem>
        {rossProfile?.user_type > 1 ? (
          <>
            {" "}
            {/* <MenuItem onClick={handleClose}>Switch User</MenuItem> */}
          </>
        ) : (
          <MenuItem
            onClick={() => {
              setOpenSwitchUser(true);
              handleClose();
            }}
          >
            {" "}
            Switch User{" "}
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>

      {openReports && (
        <>
          <ReportsModel
            openReports={openReports}
            setOpenReports={setOpenReports}
            privileges={privileges}
          />
        </>
      )}

      {adminOpenReports && (
        <>
          <AdminReportsModel
            openReports={adminOpenReports}
            setOpenReports={setAdminOpenReports}
            // privileges={privileges}
          />
        </>
      )}

      {openSwitchUser && (
        <SwitchUserModel
          openSwitchUser={openSwitchUser}
          setOpenSwitchUser={setOpenSwitchUser}
        />
      )}
    </>
  );
}
