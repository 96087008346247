import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
// import "./outOfServiceModal/outOfServiceModal.scss";
import "../outOfServiceModal/outOfServiceModal.scss";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import { FormControlLabel, Switch, Button } from "@mui/material";
import { toast } from "react-toastify";
import { CallGETAPI, CallPOSTAPI } from "../../../helper/API";
// import MessageHandler from "../common/MessageHandler";

const SiteListModel = ({selectSite, setSelectedSite, openSitelistModel, setOpenSitelistModel, setInventoryModal, section_name, setApi }) => {
  const handleClose = () => setOpenSitelistModel(false);
//   const [selectSite, setSelectedSite] = useState("");
  const [siteList, setSiteList] = useState([])
 

     const fetchLoad = async () => {
        const res = await CallGETAPI("account/fetch-site-inventory");
        console.log(res?.data?.data);
        setSiteList(res?.data?.data || []);
     }

     useEffect(() => {
        fetchLoad();
     },[])

const handleSubmit = async (e) => {
  e.preventDefault();
  let payload = {
"section_name":section_name,
    "site_id": selectSite,
  };
//   const res = await CallPOSTAPI("account/get-site-battery-inventory", payload);
setApi("account/get-site-battery-inventory", payload)
// setSelectedSite("");
//   console.log({res})
console.log(selectSite)
handleClose();
  setInventoryModal(true);
}

  return (
    <>
      <Modal
        show={openSitelistModel}
        onHide={handleClose}
        // dialogClassName="modal-half"
        // aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        style={{ maxWidth: "500px", marginLeft: "30%"  }}
        // id="outofservice-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center gap-3">
            <span>Site</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-container" id="outofservice-modal-content">
            <div className="my-modal-section">
              <div className="row">
                <div className="col-md-12 mx-auto">
                  <Form
                    class=""
                    // onSubmit={(e)=> handleSubmit(e)}
                    // noValidate
                    // validated={validated}
                    // id="outofservice-form"
                  >
                    <div
                      className="bottom-border-blue py-4 px-2"
                      style={{
                        background: "#eee",
                      }}
                    >
                      <div className="row">
                        <Form.Group className="col-md mb-3">
                          <Form.Label>Site List </Form.Label>
                          <select
                            className="form-control"
                            name="loaner_rental_serial"
                            value={selectSite}
                            onChange={(e) => setSelectedSite(e.target.value)}
                          >
                            <option value="" key={0} >
                              --Select One--
                            </option>
                            {siteList?.map((item, index) => (
                              <option key={index} value={item?.site_id}>
                                {item?.site_name}
                              </option>
                            ))}
                          </select>
                        </Form.Group>
                      </div>

                      <div className="row">
                        <Form.Group
                          className="col-md mt-4"
                          style={{ maxWidth: "120px" }}
                        >
                          <Button
                            className={"btn btn-danger"}
                            variant="danger"
                            type="button"
                            style={{ fontSize: "16px", marginTop: "2px" }}
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            Cancel
                          </Button>
                        </Form.Group>

                        <Form.Group
                          className="col-md mt-4"
                          style={{ maxWidth: "100px" }}
                        >
                          <Button
                            className={"btn btn-success"}
                            variant="success"
                            style={{ fontSize: "16px", marginTop: "2px" }}
                            type="submit"
                            onClick={(e)=> handleSubmit(e)}
                            // disabled={switchValue.value === true ? false : true}
                          >
                            Submit
                          </Button>
                        </Form.Group>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          {/* alert */}
          {/* <div className="my-4">
            <MessageHandler
              status={FormMsg.type}
              msg={FormMsg.msg}
              HandleMessage={setFormMsg}
            />
          </div> */}
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </>
  );
};

export default SiteListModel;
