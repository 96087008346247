import React,{ useState } from "react";
import DataGrid, {
  Column,
  SearchPanel,
  Paging,
} from "devextreme-react/data-grid";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../pages/accounts/Loading.jsx";

const SiteListTbl = ({ tableData, privileges,showLoading,setShowLoading }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  console.log(privileges)

  console.log(tableData);
  const RenderAccTitle = (account) => {
    return (
      <>
        <span
          role={"button link"}
          onClick={() => {
            if (privileges?.includes('site-details')) {
              setLoading(true);
              setTimeout(() => {
                setLoading(false);
                navigate(`/user/site-details/${account?.account_site_info_id}`);
                // , {
                //   state: {
                //     siteTitle: "Account : " + account?.account_site_info_id,
                //     editUrl: "/account/accounts-edit/" + account?.account_id,
                //     deleteUrl: "/account/accounts-delete/" + account?.account_id,
                //   },
                // });
              }, 0);
            }
          }}
           className={privileges?.includes('site-details') ? "link" : ""}
          >
          {account.account_site_name}
        </span>
      </>
    );
  };

  
  return (
    <>
     {(showLoading || !tableData) ? (
        <>
          <div className="showloading">
            <Loading />
          </div>
        </>
      ) : (<> 
     
      <DataGrid
        id="account-listing-table"
        dataSource={tableData}
        keyExpr="account_id"
        showBorders={true}
        // height={ 500 }
        showRowLines={true}
        columnAutoWidth={true}
        wordWrapEnabled={true}
      >
        <SearchPanel
          visible={true}
          highlightCaseSensitive={true}
          placeholder="Search by keywords..."
        />
        <Paging defaultPageSize={10} defaultPageIndex={0} />

        <Column
          dataField="account_site_name"
          caption={"Site Name"}
          dataType="string"
          cellRender={(e) => RenderAccTitle(e.data)}
        />
        <Column
          dataField="account_name"
          caption={"Account Name"}
          dataType="string"
        //   cellRender={(e) => RenderEqupment(e)}
          allowSorting={true}
        />
        <Column
          dataField="aeds"
          caption={"Equipment"}
          dataType="string"
        //   cellRender={(e) => RenderTraining(e.data)}
          allowSorting={true}
        />
        <Column
          dataField="trainingCount"
          caption={"Training"}
          dataType="string"
        />
        <Column
          dataField="account_site_poc"
          caption={"Site Poc"}
        //   cellRender={(e) => RenderAccParent(e.data)}
          dataType="string"
        />
      </DataGrid>
      </>)}
    </>
  );
};

export default SiteListTbl;
