import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatPhoneNumber } from "../../../helper/Common";
import TableSkeleton from "../skeleton/table/TableSkeleton";
import New from "../../../img/New.png";

export default function Sites({ siteDataList, edit = true, is_user = false }) {
  const navigate = useNavigate();
  const { accountId } = useParams();
  const [showLoading, setShowLoading] = React.useState(true);

  let site_url = is_user ? "/user/site-details/" : "/account/site-details/";

  useEffect(() => {
    setShowLoading(false);
  }, [siteDataList]);

  return (
    <div className="relative" style={{ marginBottom: "5%" }}>
      {/* loading */}
      {showLoading && (
        <div className="showloading-table">
          <TableSkeleton />
        </div>
      )}

      {!showLoading && (
        <>
          <Box className="d-flex justify-content-between align-items-center py-2">
            <h3 className="heading">Account Site Information</h3>
            <div style={{ display: "flex", flexDirection: "row", gap: "2px" }}>
              <Link
                style={{ textDecoration: "none", marginRight: "10px" }}
                to={"/admin-siteNew/" + accountId}
              >
                <img src={New} />
                New
              </Link>

              {/* {edit &&
                        <Link style={{ textDecoration: 'none' }} to={'/account/sites/new/' + accountId}
                        >
                            <img src={New} />
                            New
                        </Link>} */}
            </div>
          </Box>

          <table className="w-100 border-b-blue">
            <thead>
              <tr className="">
                <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
                  Main Site
                </th>
                <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
                  Equipment
                </th>
                <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
                  Training
                </th>
                <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
                  Site POC
                </th>
                <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
                  Phone
                </th>
                <th className=" py-1 px-2 bg-tbl-border border-t-blue">
                  Email
                </th>
              </tr>
            </thead>
            <tbody className="odd-even-row">
              {siteDataList.map((siteList, index) => (
                <tr className="" key={index}>
                  <td className=" py-1 px-2 tbl-border border-r-blue">
                    <Link
                      style={{
                        color: "#0C71C3",
                        fontWeight: 600,
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      to={{
                        pathname: site_url + `${siteList.account_site_info_id}`,
                        state: {
                          siteTitle: "Site: " + siteList?.account_site_name,
                          ...(is_user
                            ? {}
                            : {
                                editUrl:
                                  "/account/site-details-edit/" +
                                  siteList.account_site_info_id,
                                deleteUrl:
                                  "/account/site-details-edit/" +
                                  siteList.account_site_info_id,
                              }),
                        },
                      }}
                      className="link"
                    //   onClick={(e) => {
                    //     e.preventDefault();
                    //     navigate(site_url + siteList.account_site_info_id, {
                    //       state: {
                    //         siteTitle: "Site: " + siteList?.account_site_name,
                    //         ...(is_user
                    //           ? {}
                    //           : {
                    //               editUrl:
                    //                 "/account/site-details-edit/" +
                    //                 siteList.account_site_info_id,
                    //               deleteUrl:
                    //                 "/account/site-details-edit/" +
                    //                 siteList.account_site_info_id,
                    //             }),
                    //       },
                    //     });
                    //   }}
                    >
                      {siteList?.account_site_name}
                    </Link>
                  </td>
                  <td className=" py-1 px-2 tbl-border  border-r-blue">
                    {/* <Link to={ "account/site-details" } >
                                { siteList?.account_site_equipment }
                            </Link> */}
                    {siteList?.aedCount}/{siteList?.equipmentCount}
                  </td>
                  <td className=" py-1 px-2 tbl-border border-r-blue">
                    {siteList?.account_site_training}
                  </td>
                  <td className=" py-1 px-2 tbl-border border-r-blue">
                    {siteList?.account_site_poc}
                  </td>
                  <td className=" py-1 px-2 tbl-border border-r-blue">
                    {siteList?.account_site_phone && (
                      <a
                        className="link"
                        href={
                          "tel:" +
                          siteList?.account_site_phone_ext +
                          siteList?.account_site_phone
                        }
                      >
                        {siteList?.account_site_phone
                          ? formatPhoneNumber(siteList?.account_site_phone)
                          : ""}{" "}
                        {siteList?.account_site_phone_ext
                          ? " X " + siteList?.account_site_phone_ext
                          : ""}
                      </a>
                    )}
                  </td>
                  <td className=" py-1 px-2 tbl-border">
                    <a
                      className="link"
                      href={"mailto:" + siteList?.account_site_email}
                    >
                      {siteList?.account_site_email}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}
