import React,{ useState } from "react";
import DataGrid, {
  Column,
  SearchPanel,
  Paging,
} from "devextreme-react/data-grid";
import { Link, useNavigate } from "react-router-dom";


const EquipmentListTbl = ({ tableData, privileges }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const RenderAccTitle = (data) => {
    console.log(data);
    return (
      <>
        {/* {account.map((item, index) => (
          <span className="link" 
          onClick={() => navigate("/user/aed-details/" + item?.aed_id)}
          key={index}>
            {item.brand_name}
            <br />
          </span>
        ))} */}
          <span className={privileges?.includes("aed-details") ? "link" : ""}
          onClick={() =>  privileges?.includes("aed-details") &&
          navigate("/user/aed-details/" + data?.aed_id)}
          >
             {data?.aed_brand}
             </span>
      </>
    );
  };
  
  const RenderSerial = (data) => {
    console.log(data)
    return(<>
      <span className={privileges?.includes("aed-details") ? "link" : ""}
          onClick={() =>  privileges?.includes("aed-details") && 
          navigate("/user/aed-details/" + data?.aed_id)}
          > {data?.serial_number}</span>
    </>)
  }

  return (
    <>
     {/* {loading && (
        <>
          <div className="showloading">
            <Loading />
          </div>
        </>
      )} */}
      <DataGrid
        id="account-listing-table"
        dataSource={tableData}
        keyExpr="site_id"
        showBorders={true}
        // height={ 500 }
        showRowLines={true}
        columnAutoWidth={true}
        wordWrapEnabled={true}
      >
        <SearchPanel
          visible={true}
          highlightCaseSensitive={true}
          placeholder="Search by keywords..."
        />
        {/* <FilterPanel visible={true} /> */}

        {/* <HeaderFilter visible={true} /> */}
        {/* <Scrolling mode="infinite" /> */}
        <Paging defaultPageSize={10} defaultPageIndex={0} />

        <Column
          dataField="aed_brand"
          caption={"AED brand"}
          dataType="string"
          cellRender={(e) => RenderAccTitle(e?.data)}
        />
        <Column
          dataField="serial_number"
          caption={"Serial #"}
          dataType="string"
          cellRender={(e) => RenderSerial(e?.data)}
          allowSorting={true}
        />
        <Column
          dataField="aeds"
          dataType="string"
          caption={"Last Check"}
        //   cellRender={(e) => RenderTraining(e.data)}
          allowSorting={true}
        />
        <Column
          dataField="trainingCount"
          caption={"Last Service"}
          dataType="string"
        />
        <Column
          dataField="account_site_poc"
          caption={"RMS Check"}
        //   cellRender={(e) => RenderAccParent(e.data)}
          dataType="string"
        />
        <Column
          dataField="account_name"
          caption={"Account"}
          dataType="string"
          // cellRender={(e) => RenderAccount(e.data)}
          allowSorting={true}
        />
        <Column
          dataField="site_name"
          dataType="string"
          caption={"Site"}
          // cellRender={(e) => RenderSite(e)}
          allowSorting={true}
        />
      </DataGrid>
    </>
  );
};

export default EquipmentListTbl;
