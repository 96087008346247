import React, { useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { FormControlLabel, Icon, Radio, Switch } from "@mui/material";
import { Form, Button as BButton, Button as BsButton } from "react-bootstrap";
import Button from '@mui/material/Button';
import { validatePhone } from '../../helper/Common';
import "../../../src/global.css";
import { toast } from 'react-toastify';


export default function AddContactPhoneFrom({ altTrainerForm, setSubFormData, increaseAlternative, decreaseAlternative, handleInputChange, allDropDowns, formData, formName, setFormData, noBtns, setPhoneValidation, phoneValidation }) {

  // const [subFormData,setSubFormData] =  useState({"phone_number":"","ext":"","phone_type_id":"","main":""}); 
  const [allPhoneData, setAllPhoneData] = useState([]);
  const [phone, setPhone] = useState("");
  const [phoneExt, setPhoneExt] = useState("");

  const [phoneType, setPhoneType] = useState(1);
  const [main, setMain] = useState(false);
  const [phoneValidations, setPhoneValidations] = useState([]);

  useEffect(() => {
    const initialValidations = altTrainerForm.map(() => ({
      account_main_contact_phone: 0,
    }));
    setPhoneValidations(initialValidations);
  }, [altTrainerForm]);

  const toggleMainRadioButton = (index) => {
    const newArr = [...altTrainerForm];
    const currentForm = newArr[index];
    if (currentForm) {
      currentForm.account_main_contact_phone_main = currentForm.account_main_contact_phone_main === 1 ? 0 : 1;
      setSubFormData(newArr);
    }
  };

  const updatePhoneFeilds = (e, name, index) => {
    const newArr = [...altTrainerForm];
    newArr[index][name] = e.target.value
    setSubFormData(newArr);
  }

  const updateFieldChanged = (e, index, name) => {
    let newArr = [...altTrainerForm];

    if (e.target.name === "account_main_contact_phone") {
      e.target.value = e.target.value.replace(/[^0-9 ]/g, "").trim();
      e.target.value = e.target.value.slice(0, 10);

      newArr[index][name] = e.target.value;
    }

    if (name === "account_main_contact_phone_main") {
      newArr[index][name] = true;

      newArr = newArr.map((item, idx) => ({
        ...item,
        [name]: idx === index,
      }));
    } else {
      newArr[index][name] = e.target.value;
    }

    setSubFormData(newArr);
  };

  const handlePhoneChange = (e, index) => {
    const phoneNumber = e.target.value;
    if (validatePhoneNumber(phoneNumber)) {
      // Update phone validation state for the specific index
      setPhoneValidations((prevValidations) => {
        const updatedValidations = [...prevValidations];
        updatedValidations[index] = { account_main_contact_phone: false };
        return updatedValidations;
      });
      updateFieldChanged(e, index, "account_main_contact_phone");
    } else {
      // Update phone validation state for the specific index
      setPhoneValidations((prevValidations) => {
        const updatedValidations = [...prevValidations];
        updatedValidations[index] = { account_main_contact_phone: true };
        return updatedValidations;
      });
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  console.log(altTrainerForm)

  return (
    <>
      {altTrainerForm.map((singleForm, index) => (

        <div className='row mb-4 ' style={{ display: 'flex', alignItems: 'center' }} key={index}  >

          <Form.Group className={'col NewContatctFormPhoneField'}>
            <div className='d-flex mb-2' style={{ alignItems: 'center' }} >
              <Form.Label>Phone </Form.Label>
              {noBtns && <>
                <button onClick={increaseAlternative} type="button" className="btn mx-2 btn-sm btn-primary " >+</button>
                <button onClick={decreaseAlternative} type="button" className="btn mx-2 btn-sm btn-danger " >-</button>
              </>}
            </div>

            <Form.Control
              type="tel"
              name="account_main_contact_phone"
              defaultValue={singleForm?.account_main_contact_phone ?? singleForm?.phone_number}
              // onChange={(e) => updateFieldChanged(e, index)}
              // onChange={(e) => updatePhoneFeilds(e, 'account_main_contact_phone', index)}
              // required
              onChange={(e) => handlePhoneChange(e, index)}
              pattern="[0-9]{10}"
              onKeyPress={(e) => {
                const regex = /^[0-9\b]+$/;
                if (!regex.test(e.key)) {
                    e.preventDefault();
                }
            }}
              minLength="10"
              maxLength="10"
              disabled={singleForm.account_main_contact_phone_main === true ? true : false}
              className={
                phoneValidations[index]?.account_main_contact_phone
                  ? "phone-invalid-input"
                  : ""
              }
            />

            {phoneValidations[index]?.account_main_contact_phone ? (
              <>
                <div className="phone-invalid">
                  Please Enter Exact 10 digits.
                </div>
              </>
            ) : (
              <>
                <Form.Control.Feedback type="invalid">
                  Please Enter Exact 10 digits.
                </Form.Control.Feedback>
              </>
            )}
          </Form.Group>


          <Form.Group className={'col NewContatctFormEXTPhoneField'}>
            <Form.Label>Ext</Form.Label>
            <Form.Control type="number"
              disabled={singleForm.account_main_contact_phone_main === true ? true : false}
              name="account_main_contact_phone_ext"
              onChange={(e) => updatePhoneFeilds(e, 'account_main_contact_phone_ext', index)}
              defaultValue={singleForm?.account_main_contact_phone_ext ?? singleForm?.ext}
            />

          </Form.Group>

          <Form.Group className={'col NewContatctFormPhoneField'}>
            <Form.Label>Phone Type</Form.Label>
            <Form.Select className={''}
              disabled={singleForm.account_main_contact_phone_main === true ? true : false}
              name="phone_type_id"
              onChange={(e) => updatePhoneFeilds(e, 'phone_type_id', index)}
              value={singleForm.phone_type_id}
            >
              <option value="0" selected >--Select One--</option>
              {allDropDowns?.phoneType && allDropDowns?.phoneType.map((PT, index) => (
                <option value={PT.dropdown_phone_type_id} key={index}>{PT.dropdown_phone_type_name
                }</option>
              ))}

            </Form.Select>
          </Form.Group>

          <Form.Group className={'col d-block'}>
            <b className={''}>Main</b>
            <div className="">
              <FormControlLabel
                className={''}
                label=""
                value={true}
                name="account_main_contact_phone_main"
                onClick={(e) => {
                  // Check if phone field is empty
                  const phoneNumber =
                    altTrainerForm[index].account_main_contact_phone;
                  if (!phoneNumber || phoneNumber.trim() === "") {
                    toast.error("Please first fill Phone number");
                  } else {
                    e.preventDefault();
                    updateFieldChanged(
                      e,
                      index,
                      "account_main_contact_phone_main"
                    );
                  }
                }}
                control={
                  <Radio
                    name={index}
                    value={singleForm.account_main_contact_phone_main}
                    checked={singleForm.account_main_contact_phone_main == true}
                    // onClick={() => toggleMainRadioButton(index)}
                    color="primary"
                    size="medium"
                  />}
              />
            </div>
          </Form.Group>
        </div>

      ))
      }
    </>
  )
}
