import React, { useState, useEffect, useRef } from "react";
import "./header.scss";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../img/red-logo.svg";
// import RedLogo from '../../img/red-logo.png';
import RedLogo from "../../img/red-logo.svg";

import SearchForm from "./SearchForm";
import NavDropdown from "react-bootstrap/NavDropdown";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";

import Nav from "react-bootstrap/Nav";
import { Link, useNavigate } from "react-router-dom";
import SubHeading from "./SubHeading";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Button } from "@mui/material";
import { MenuLinks, UserMenuLinks } from "../../utils";
import SidebarLink from "../layout/SidebarLink";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material";
import Offcanvas from "react-bootstrap/Offcanvas";
import Loading from "../../pages/accounts/Loading";
import PermissionManagerMenu from "../../utils/PermissionManagerMenu";
import "../../../src/global.css";
import { CallGETAPI } from "../../helper/API";

const drawerWidth = 200;
const theme = createTheme();

export default function Header({
  isSearch,
  title,
  sideMenubar,
  setSideMenubar,
}) {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openn = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);
  const wrapperRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [permissionArray, setPermissionArray] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSideMenubar(false);
  };

  useEffect(() => {
    // if(sideMenubar && open) {
    //   handleDrawerClose();
    // }
  }, []);

  const handleImageClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      user?.user_type === 3
        ? navigate("/user-dashboard")
        : navigate("/dashboard");
    }, 2000);
  };

  const user = JSON.parse(localStorage.getItem("ross-profile"));

  const fetchPrivilege = async () => {
    let response = await CallGETAPI("auth/priviledge");
    if (response.status && response?.data?.permission) {
      let permission = response?.data?.permission;
      localStorage.setItem("permissions", permission);
      const permissionsArray = permission;
      setPermissionArray(permissionsArray);
    }
  };

  useEffect(() => {
    fetchPrivilege();
  }, []);

  function compareTitles(userMenuLinks, privilege) {
    for (let i = 0; i < userMenuLinks.length; i++) {
      if (permissionArray.includes(userMenuLinks[i].title)) {
        return true;
      }
    }
    return false;
  }

  const result = compareTitles(UserMenuLinks, permissionArray);
  console.log(result);

  return (
    <>
      {/* off canvas */}
      <div className="sidebar-main">
        <Offcanvas
          className="sidebar"
          show={open}
          onHide={handleDrawerClose}
          style={{ backgroundColor: "black" }}
        >
          {/* header */}
          <Offcanvas.Header style={{ justifyContent: "flex-end" }}>
            <IconButton
              onClick={handleDrawerClose}
              style={{ backgroundColor: "white" }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Offcanvas.Header>
          {/* main body */}
          <Offcanvas.Body>
            <List className="TabletSizeFont">
              {user?.user_type == 0 ? (
                <>
                  {MenuLinks.map((e, index) => (
                    <SidebarLink details={e} setOpen={setOpen} key={index} />
                  ))}
                </>
              ) : (
                <>
                  {/* {UserMenuLinks.map((e, index) => (
            permissionArray.includes(e.privillage) && <SidebarLink details={e} setOpen={setOpen} key={index} />
          ))} */}

                  {UserMenuLinks.map(
                    (e, index) =>
                      (e.title === "Home" ||
                        permissionArray.includes(e.privillage)) && (
                        <SidebarLink
                          details={e}
                          setOpen={setOpen}
                          key={index}
                        />
                      )
                  )}
                </>
              )}
              {user?.user_type > 1 ? (
                <></>
              ) : (
                <>
                  <PermissionManagerMenu setOpen={setOpen} />
                </>
              )}
            </List>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      {/* navbar */}
      <Navbar
        bg={isSearch ? "black" : "white"}
        expand="lg"
        className="Navbar"
        // style={{ padding: "0 45px 0 45px" }}
      >
        <Container fluid className="MainNavbarDiv">
          <Navbar.Brand href="#" className="d-flex">
            <div className="d-flex NavbarBrandDiv">
              {/* {user?.user_type > 1 ? "" : */}
              <div>
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className="MenuIcon"
                  sx={{ mr: 2, ...(openn && { display: "none" }) }}
                >
                  <MenuIcon style={{ color: "white" }} />
                </IconButton>
              </div>
              {/* } */}
              <div className="Navbar-Brand-wrapper">
                <header className={"navbar-brand"}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <img
                        className="brand-img"
                        src={isSearch ? "/red-logo.svg" : "/red-logo.svg"}
                        // width="410px"
                        onClick={handleImageClick}
                      />
                    </div>

                    <div>{isSearch ? <SearchForm user={user} /> : ""}</div>
                  </div>
                </header>
              </div>
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>

      {isSearch ? (
        ""
      ) : (
        <Navbar style={{ background: "#0c71c3" }}>
          <Container fluid>
            <Navbar.Collapse id="bottom-navbar">
              <Nav className="ml-auto">
                <Nav.Link className="nav-link" href="#home">
                  Training
                </Nav.Link>
                <Link to={"/calendar"} className="nav-link nav-link">
                  Calendar
                </Link>
                <Link className="nav-link" to="/">
                  Login
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}

      {loading && (
        <>
          <div className="showloading">
            <Loading />
          </div>
        </>
      )}
    </>
  );
}
