import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "./outOfServiceModal/outOfServiceModal.scss";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import { FormControlLabel, Switch, Button } from "@mui/material";
import { toast } from "react-toastify";
import MessageHandler from "../common/MessageHandler";
import { CallGETAPI, CallPOSTAPI } from "../../helper/API";

const SwitchUserModel = ({ openSwitchUser, setOpenSwitchUser }) => {
  const handleClose = () => setOpenSwitchUser(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedUser, setSelectedUser] = useState("")
  const [accounts, setAccounts] = useState([]);
  const [userList, setUserList] = useState([]);
  const [impersonationToken, setImpersonationToken] = useState(null);

  const fetchOnLoad = async () => {
    const accountsData = await CallGETAPI("account/account-list");
    console.log(accountsData);
    if (accountsData?.status) {
      setAccounts(accountsData?.data?.data?.account);
    }
  };

  const fetchUserList = async () => {
    console.log({selectedAccount})
    if(selectedAccount){
    const res = await CallGETAPI("account/activeuser-based-contacts-list/" + selectedAccount)
    setUserList(res?.data?.data?.contact_list);
    console.log(res)
    console.log(res?.data?.data?.contact_list)
    }
  }

  useEffect(() => {
   fetchUserList();
  },[selectedAccount])

  useEffect(() => {
    fetchOnLoad();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      account_id: selectedAccount,
      contact_id: selectedUser
    };
    const res = await CallPOSTAPI("account/switch-user/", payload);
    const token = res?.data?.token;
    if (token) {
      // Store the token in sessionStorage
      sessionStorage.setItem("ross_token", token);
      localStorage.setItem("ross_token", token);
      // Redirect to the user's dashboard
      redirectToUserDashboard(selectedUser);
    } else {
      toast.error("Token not received from the server.");
    }
  };
  
  const redirectToUserDashboard = (userId) => {
    const token = sessionStorage.getItem("ross_token");
    const localtoken = localStorage.getItem("ross_token");
    console.log({localtoken})
    if (token) {
      // Construct the user's dashboard URL with the token
      const userProfileUrl = `/user-dashboard1/user-profile/${userId}`;
      const urlWithToken = `${userProfileUrl}?token=${token}`;
      // Open the URL in a new tab
      window.open(urlWithToken, '_blank');
    } else {
      toast.error("Token not found. Please try again.");
    }
  };
  
  // const redirectToUserDashboard = (userId) => {
  //   const token = sessionStorage.getItem("ross_token");
  //   const localtoken = localStorage.getItem("ross_token");
  //   console.log({localtoken})
  //   if (token) {
  //     // Construct the user's dashboard URL with the token
  //     const userProfileUrl = `/user-dashboard1/user-profile/${userId}`;
  //     const urlWithToken = `${userProfileUrl}?token=${token}`;
  //     // Open the URL in a new incognito window
  //     const newWindow = window.open(urlWithToken, '_blank', 'noopener,noreferrer');
  //     if (newWindow) {
  //       newWindow.opener = null;  // Ensure the new window doesn't have access to the opener window
  //     }
  //   } else {
  //     toast.error("Token not found. Please try again.");
  //   }
  // };
  
  
  
  return (
    <>
      <Modal
        show={openSwitchUser}
        onHide={handleClose}
        // dialogClassName="modal-half"
        // aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        style={{ maxWidth: "500px", marginLeft: "30%" }}
        id="outofservice-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center gap-3">
            <span>Switch User</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-container" id="outofservice-modal-content">
            <div className="my-modal-section">
              <div className="row">
                <div className="col-md-12 mx-auto">
                  <Form
                    class=""
                    // onSubmit={handleSubmit}
                    noValidate
                    // validated={validated}
                    id="outofservice-form"
                  >
                    <div
                      className="bottom-border-blue py-4 px-2"
                      style={{
                        background: "#eee",
                      }}
                    >
                      <div className="row">
                        <Form.Group className="col-md mb-3">
                          <Form.Label>Account List </Form.Label>
                          <select
                            className="form-control"
                            name="account_list"
                            value={selectedAccount}
                            onChange={(e) => setSelectedAccount(e.target.value)}
                          >
                            <option value="" key={0} selected>
                              --Select One--
                            </option>
                            {accounts.map((item, index) => (
                              <option key={index} value={item.account_id}>
                                {item.account_name}
                              </option>
                            ))}
                          </select>
                        </Form.Group>
                      </div>

                      <div className="row">
                        <Form.Group className="col-md mb-3">
                          <Form.Label>User List </Form.Label>
                          <select
                            className="form-control"
                            name="loaner_rental_serial"
                            value={selectedUser}
                            onChange={(e) => setSelectedUser(e.target.value)}
                          >
                            <option value="" key={0} selected>
                              --Select One--
                            </option>
                            <option value="" key={0} selected>
                              --Select One--
                            </option>
                            {userList?.map((item, index) => (
                              <option key={index} value={item.contact_id}>
                                {item.contact_name}
                              </option>
                            ))}
                          </select>
                        </Form.Group>
                      </div>

                      <div className="row">
                        <Form.Group
                          className="col-md mt-4"
                          style={{ maxWidth: "120px" }}
                        >
                          <Button
                            className={"btn btn-danger"}
                            variant="danger"
                            type="button"
                            style={{ fontSize: "16px", marginTop: "2px" }}
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            Cancel
                          </Button>
                        </Form.Group>

                        <Form.Group
                          className="col-md mt-4"
                          style={{ maxWidth: "100px" }}
                        >
                          <Button
                            className={"btn btn-success"}
                            variant="success"
                            style={{ fontSize: "16px", marginTop: "2px" }}
                            type="submit"
                            onClick={(e) => handleSubmit(e)}
                            // disabled={switchValue.value === true ? false : true}
                          >
                            Submit
                          </Button>
                        </Form.Group>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          {/* alert */}
          {/* <div className="my-4">
            <MessageHandler
              status={FormMsg.type}
              msg={FormMsg.msg}
              HandleMessage={setFormMsg}
            />
          </div> */}
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </>
  );
};

export default SwitchUserModel;
