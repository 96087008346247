import React, { useEffect, useState } from 'react';
import { FormControlLabel, Icon, Radio, Switch } from "@mui/material";
import { Form, Button as BButton, Button as BsButton } from "react-bootstrap";
import Button from '@mui/material/Button';
import "../../../src/global.css";
import { toast } from "react-toastify";

export default function AddContactMultiEmailForm({
  altTrainerForm, setSubFormData,
  increaseAlternative, decreaseAlternative,
  handleInputChange, allDropDowns, noBtns = true,
}) {

  const updateEmailFeilds = (e, name, index) => {
    const newArr = [...altTrainerForm];
    newArr[index][name] = e.target.value
    setSubFormData(newArr);
  }

  const toggleMainRadioButton = (index) => {
    const newArr = [...altTrainerForm];
    newArr[index].account_main_contact_email_main = newArr[index].account_main_contact_email_main === 1 ? 0 : 1; // Toggle between 0 and 1
    setSubFormData(newArr);
  };

  const updateFieldChanged = (e, index, name) => {
    const newArr = [...altTrainerForm];
    if (name === `account_main_contact_email_main`) {
      newArr.forEach((form, i) => {
        newArr[i].account_main_contact_email_main = i === index ? 1 : 0;
      });
    } else {
      newArr[index][name] = e.target.value;
    }
    setSubFormData(newArr);
  };

  return (
    <>
      {altTrainerForm.map((singleForm, index) => (
        <div className='row my-4' key={index}>

          <Form.Group className={'col NewContatctFormPhoneField'}>
            <div className='d-flex'>
              <Form.Label>Email* </Form.Label>
              {noBtns && <>
                <button type="button" onClick={increaseAlternative} className="btn py-1 btn-sm mx-2 btn-primary" >+</button>
                <button type="button" onClick={decreaseAlternative} className="btn py-1 btn-sm mx-2 btn-danger" >-</button>
              </>
              }
            </div>

            <Form.Control type="email" required name="account_main_contact_email" defaultValue={singleForm.account_main_contact_email}
              onChange={(e) => updateEmailFeilds(e, 'account_main_contact_email', index)}
              disabled={singleForm.account_main_contact_email_main === 1 ? true : false} />
            <Form.Control.Feedback type="invalid">
              Please Enter Email.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className={'col NewContatctFormPhoneField'}>
            <Form.Label>Email Type</Form.Label>
            <Form.Select className={''}
              disabled={singleForm.account_main_contact_email_main === 1 ? true : false}
              name="email_type_id"
              onChange={(e) => updateEmailFeilds(e, 'email_type_id', index)}
              value={singleForm?.email_type_id}
            >
              <option value="0" selected >--Select One--</option>
              {allDropDowns?.emailType && allDropDowns?.emailType.map((ET, index) => (
                <option value={ET.dropdown_email_type_id} key={index}>{ET.dropdown_email_type_name
                }</option>
              ))}

            </Form.Select>
          </Form.Group>

          <>
            <Form.Group className={'col'}>
              <b className={''}>Main</b>
              <div className="" >
                <FormControlLabel
                  className={''}
                  label=""
                  onClick={(e) => {
                    // Check if phone field is empty
                    const email =
                      altTrainerForm[index].account_main_contact_email;
                    if (!email || email.trim() === "") {
                      toast.error("Please first fill Email");
                    } else {
                      e.preventDefault();
                      updateFieldChanged(
                        e,
                        index,
                        "account_main_contact_email_main"
                      );
                    }
                  }}
                  control={
                    <Radio
                      color="primary"
                      size="medium"
                      value={singleForm.account_main_contact_email_main}
                      name={`main-${index}`}
                      checked={singleForm.account_main_contact_email_main === 1}
                      // onClick={() => toggleMainRadioButton(index)}
                    />
                  }
                />
              </div>
            </Form.Group>
          </>
        </div>
      ))}
    </>
  )
}
