import React, { useState } from 'react'
import { Link, Route, useParams } from 'react-router-dom';
import TableTopBar from './userComp/TableTopBar';
import { useEffect } from 'react';
import { CallGETAPI } from '../helper/API';
import Contacts from '../pages/accounts/sites/tabs/Contacts';
import UserEquipments from './userComp/UserEquipment';
import UserTraningDetailsTable from '../pages/accounts/userTrainingTabel/UserTraningDetailsTable';
import UserNotes from './userComp/UserNotes';
import UserEmails from './userComp/UserEmails';
import UserSupport from './userComp/UserSupport';
import UserRFI from './userComp/UserRFI';
import { useLocation } from 'react-router-dom';
import UserSites from './userComp/UserSites';
import UserContacts from './userComp/UserContacts';
import UserDocuments from './userComp/UserDocuments';
import UserInpersonNew from './userComp/UserInpersonNew';
import UserTraningNewComp from './userComp/UserTrainingNewComp';
import UserPops from './userComp/UserPop';
import New from '../img/New.png';
import { useNavigate } from 'react-router-dom';
import { MainDashboardTopTabLists, filteredDetailsTabs } from '../helper/constants';
import PermissionDeniedPage from './userComp/PermissionDeniedPage';
import CircularLoadingComp from './userComp/CircularLoadingComp';

export default function UserDashboard() {

  const navigate = useNavigate()
  const location = useLocation()
  const { tab } = useParams();
  const selectedTab = tab || 'Sites';
  const [siteDataList, setsiteDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [privileges, setPrivileges] = useState([])
  const [isOpen, setIsOpen] = useState(false);

  const [accountName, setAccountName] = useState('')

  let userData = JSON.parse(localStorage.getItem("ross-profile"))
  let userPositionArr = userData?.position.split(',')
  let account_id = userData?.account_id
  let contact_id = userData?.contact_id

  const fetchPrivilege = async () => {
    let response = await CallGETAPI('auth/priviledge')
    if (response.status) {
      setPrivileges(response?.data?.permission)
      localStorage.setItem('permissions', response?.data?.permission)
    }
  }

  const fetchAccountName = async () => {
    const response = await CallGETAPI(`account/account_info_detail/${account_id}`)
    if (response?.status) {
      setAccountName(response?.data?.data?.AccountDetails?.account_name)
    }
  }

  const permissionTabsArr = privileges.map(id => {
    const foundItem = MainDashboardTopTabLists.find(item => item.id === id);
    return foundItem ? foundItem.name : null;
  }).filter(name => name !== null);


  const [tabMatched, setTabMatched] = useState(false)

  useEffect(() => {
    for (let index = 0; index < permissionTabsArr.length; index++) {
      let element = permissionTabsArr[index];
      if (element === tab) {
        setTabMatched(true);
        return;
      }
    }
    setTabMatched(false);
  }, [tab, permissionTabsArr]);

  let tabs = location?.state?.tabs

  console.log('privileges', privileges);

  const fetchLoad = async () => {
    let result = await CallGETAPI('user/user-site-list-v2');
    if (result?.status) {
      let siteData = result?.data?.data || [];
      setsiteDataList(siteData);
    }
  }

  useEffect(() => {
    fetchLoad();
    fetchPrivilege()
    fetchAccountName()

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const subHeading = () => {
    return (
      <div style={{ display: 'flex', gap: 15, width: '100%', justifyContent: 'left', marginBottom: '1%' }}>
        {privileges.includes('new-account') && (
          <Link style={{ textDecoration: 'none' }} to={'/new-account'}>
            <img src={New} alt="New" />
            New
          </Link>
        )}
        {privileges.includes('account-edit') && (
          <Link style={{ textDecoration: 'none' }} to={'/account/accounts-edit/' + account_id}>
            <img src={New} alt="New" />
            Edit
          </Link>
        )}
        {privileges.includes('assign-aed') && (
          <Link style={{ textDecoration: 'none' }} to={'/assign-quipment/' + account_id}>
            <img src={New} alt="New" />
            Assign AED
          </Link>
        )}
      </div>
    )
  }

  const documentRedirect = () => {
    navigate("/account-document-upload", {
      state: {
        type: "account",
        accountId: account_id,
        // siteId: "",
      },
    });
  };

  const supportRedirect = () => {
    const stateData = {
      type: "Account",
      site_id: 0,
      accountId: account_id ? account_id : 0,
      contactId: 0,
      accountName: accountName || "",
      support_name: accountName,
    };

    navigate("/account/new-support/" + account_id, { state: stateData });
  };

  const notesRedirect = () => {
    navigate(`/account/new-note?account_id=${account_id}`);
  };

  const handleHoverFloating = () => {
    setIsOpen(true);
  };

  const handleLeaveFloating = () => {
    setIsOpen(false);
  };

  return (
    <>
      {
        loading ?
          <>
            <CircularLoadingComp />
          </>
          :
          <>
            {
              tabMatched ?
                <>
                  <div className='mt-4' style={{ paddingInline: "45px" }} >
                    <div className="row" >
                      {/* <div className='col-md-12 mx-auto'  >
                        {subHeading()}
                        <TableTopBar tab={selectedTab} userPositionArr={userPositionArr} tabs={tabs} account_id={account_id} />
                        {selectedTab == 'Sites' && <UserSites siteDataList={siteDataList} edit={false} is_user={true} tabs={tabs} privileges={privileges} account_id={account_id} />}
                        {selectedTab == 'Contacts' && <UserContacts is_user={true} tabs={tabs} privileges={privileges} account_id={account_id} />}
                        {selectedTab == 'Equipment' && <UserEquipments is_user={true} tabs={tabs} privileges={privileges} account_id={account_id} contact_id={contact_id} />}
                        {selectedTab == 'Training' && <UserTraningNewComp is_user={true} privileges={privileges} account_id={account_id} />}
                        {selectedTab == 'POPS' && <UserPops is_user={true} privileges={privileges} account_id={account_id} />}
                        {selectedTab == 'Alerts' && <UserTraningDetailsTable is_user={true} />}
                        {selectedTab == 'Training distribution' && <UserTraningDetailsTable is_user={true} />}
                        {selectedTab == 'Inperson' && <UserInpersonNew is_user={true} tabs={tabs} privileges={privileges} account_id={account_id} />}
                        {selectedTab == 'Notes' && <UserNotes is_user={true} tabs={tabs} privileges={privileges} account_id={account_id} contact_id={contact_id} />}
                        {selectedTab == 'Emails' && <UserEmails is_user={true} tabs={tabs} privileges={privileges} account_id={account_id}/>}
                        {selectedTab == 'Support' && <UserSupport is_user={true} tabs={tabs} privileges={privileges} account_id={account_id}
                        stateData={{
                          type: "User",
                          site_id: "",
                          accountId: account_id ? account_id : 0,
                          contactId: 0,
                          accountName: "",
                          support_name: "",
                        }}
                        />}
                        {selectedTab == 'Documents' && <UserDocuments is_user={true} tabs={tabs} privileges={privileges} account_id={account_id} />}
                        {selectedTab == 'RFI' && <UserRFI is_user={true} account_id={account_id} privileges={privileges} />}
                      </div> */}
                    </div>

                    {
                      (privileges.includes('new-document') || privileges.includes('new-support') || privileges.includes('new-note')) && (
                        <div className="floating-menu-btn d-flex flex-column gap-2" style={{ marginBottom: '2%' }}
                          onMouseEnter={handleHoverFloating} onMouseLeave={handleLeaveFloating}
                        >
                          {isOpen && (
                            <>

                              {privileges.includes('new-document') && (
                                <img
                                  src="/NewDocument.svg"
                                  width={60}
                                  height={60}
                                  style={{
                                    padding: "2px",
                                    borderRadius: "50%",
                                    borderColor: "#0c71c3",
                                    borderWidth: "3px",
                                    borderStyle: "solid",
                                  }}
                                  className="pointer bg-white"
                                  onClick={documentRedirect}
                                  title="New Document"
                                />
                              )}

                              {privileges.includes('new-support') && (
                                <img
                                  src="/NewSupport.svg"
                                  width={60}
                                  height={60}
                                  style={{
                                    padding: "2px",
                                    borderRadius: "50%",
                                    borderColor: "#0c71c3",
                                    borderWidth: "3px",
                                    borderStyle: "solid",
                                  }}
                                  className="pointer bg-white"
                                  onClick={supportRedirect}
                                  title="New Support"
                                />
                              )}


                              {privileges.includes('new-note') && (
                                <img
                                  src="/NewNote.svg"
                                  width={60}
                                  height={60}
                                  style={{
                                    padding: "2px",
                                    borderRadius: "50%",
                                    borderColor: "#0c71c3",
                                    borderWidth: "3px",
                                    borderStyle: "solid",
                                  }}
                                  className="pointer bg-white"
                                  onClick={notesRedirect}
                                  title="New Note"
                                />
                              )}

                            </>
                          )}

                          <img
                            src="/Plus.svg"
                            width={60}
                            height={60}
                            style={{
                              padding: "2px",
                              borderRadius: "50%",
                              borderColor: "#0c71c3",
                              borderWidth: "3px",
                              borderStyle: "solid",
                            }}
                            className="pointer bg-white"
                            onClick={() => {
                              setIsOpen((prev) => !prev);
                            }}
                          />
                        </div>
                      )
                    }
                  </div>
                </>
                :
                <>
                  <PermissionDeniedPage />
                </>
            }
          </>
      }
    </>
  )
}
