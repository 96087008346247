import React, { useState } from "react";
import { NewModiFicationGroupIcon } from "../../../../helper/Icons";
import SiteListModel from "../../../modals/AED/SiteListModel";
// import AEDInventoryModal from '../../../../pages/accounts/AedMain/AEDInventoryModal';
import NewAedInventoryModel from "../../../modals/AED/NewAedInventoryModel";
import CustomToggleButton from "../../../common/toggleSwitch/CustomToggleButton";
import QuestionMark from "../../../../img/QuestionMark.png";
import Activitycheck from "../../../../img/Activity Symbols.png";
import Minus from "../../../../img/Minus.svg";

const EditActionsComp = ({
  index,
  crrIndex,
  formData,
  setFormData,
  section_name,
  BatteryList,
  handleInputChange,
  is_readonly = 0,
  crrFormData,
  unKnownToggleKey,
}) => {
  const [openSitelistModel, setOpenSitelistModel] = useState(false);
  const [inventoryModal, setInventoryModal] = useState(false);
  const [selectSite, setSelectedSite] = useState("");
  const [api, setApi] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handle_unknow_checkbox = (e, keyname, toogleKeyName) => {
    const oldData = { ...formData };

    const save_obj = {
      battery_type_id: e.target.checked ? "unknown" : "",
      battery_expiration: e.target.checked ? "unknown" : "",
      battery_lot: e.target.checked ? "unknown" : "",
      battery_uid: e.target.checked ? "unknown" : "",
      v9_install: e.target.checked ? "unknown" : "",
      install_before_date: e.target.checked ? "unknown" : "",
      date_installed: e.target.checked ? "unknown" : "",
      manufactured_date: e.target.checked ? "unknown" : "",
    };

    if (oldData[keyname]) {
      let newArr = [];
      for (let ari = 0; ari < oldData[keyname].length; ari++) {
        newArr.push(save_obj);
      }
      oldData[keyname] = newArr;
      oldData[toogleKeyName] = e.target.checked;
      setFormData(oldData);
    }
  };

  const handleCheckboxClick = () => {
    setIsChecked((prevState) => !prevState);
    const e = { target: { checked: !isChecked } };
    handle_unknow_checkbox(e, section_name, unKnownToggleKey);
  };

  const handleDNDButton = (index, Bindex, row, section_name, type) => {
    const Fd = {...formData};
    console.log({index, Bindex, row, type})
    let is_found = 0;
    for (let i2 = 0; i2 < Fd?.[index]?.dni_array_list.length; i2++) {
      let element = Fd?.[index]?.dni_array_list[i2];
      if (type === "battery") {
        if (element.bid === row.bid) {
          is_found = 1;
        }
      } else {
        if (element.pid === row.pid) {
          is_found = 1;
        }
      }
    }
    const checkArr = Fd?.[index]?.dni_array_list?.find(
      (it) =>
        it.aed_id === row.aed_id &&
        (it?.bid === row?.bid || it?.pid === row?.pid)
    );
    if (!is_found) {
      //toast.success('DNI Addedd Successfully');
      Fd.dni_array_list.push(row);
      if (type === "battery") {
        console.log(row)
        row.accessory_type = 'Battery'
        console.log({Fd})
        console.log(Fd[`${section_name.replace(/'/g, '')}`]);
        const biArr = Fd[`${section_name.replace(/'/g, '')}`];
        console.log({biArr})
        let dniArr = Fd.dni_array_list;
        console.log(Fd.deletedBatteried)
        const deletedBatteryArr = Fd.deletedBatteried;
        const removedBattery = biArr[Bindex];
        Fd.deletedBatteried = [...deletedBatteryArr, removedBattery];
        Fd[`${section_name.replace(/'/g, '')}`] = biArr.filter((_, i) => i !== Bindex);
        dniArr = [removedBattery];
        setFormData(Fd);
      } else {
        if (row?.section_name === 'pediatric_pad_info' || row?.section_name === 'spare_padric_pad_info') {
          row.accessory_type = 'Pediatric Pad'
        }
        if (row?.section_name === 'adult_pad_info' || row?.section_name === 'spare_adult_pad_info') {
          row.accessory_type = 'Pad'
        }
        if (row?.section_name === 'adult_pad_pak_info' || row?.section_name === 'spare_adult_pad_pak_info') {
          row.accessory_type = 'Pad Pak'
        }
        const piArr = Fd[index].all_pads;
        let dniArr = Fd[index].dni_array_list;
        const deletedPadsArr = Fd[index].deletedPads;
        const removedPad = piArr[Bindex];
        Fd[index].deletedPads = [...deletedPadsArr, removedPad];
        Fd[index].all_pads = piArr.filter((_, i) => i !== Bindex);
        dniArr = [removedPad];
        setFormData(Fd);
      }
      setFormData(Fd);
    }
  };


  const handleRemoveMainBatteryRow = (index, Bindex, ret = 0, type , section_name) => {
    const Fd = {...formData};
    const biArr = Fd[`${section_name.replace(/'/g, '')}`];
    const deletedArr = Fd.deletedBatteried;
    const removedBattery = biArr[Bindex];
    Fd[`${section_name.replace(/'/g, '')}`] = biArr.filter((_, i) => i !== Bindex);
    Fd.deletedBatteried = [...deletedArr, removedBattery];
    setFormData(Fd);
    return ret ? Fd : undefined;
  };

  // BatteryList
  return (
    <>
      <div className="col form-group" style={{ maxWidth: "300px" }}>
        <label htmlFor="">Actions</label>
        <div>
          <span
            onClick={isChecked == true ? undefined  : setOpenSitelistModel}
            style={{ cursor: "pointer", marginRight: "2px" }}
            disabled={isChecked == true}
          >
            {" "}
            <NewModiFicationGroupIcon />{" "}
          </span>
          <span>
            <img
              src={QuestionMark}
              alt=""
              onClick={handleCheckboxClick}
              style={{ height: "20px", width: "20px", marginLeft: "5px" }}
            />
          </span>

          <span>
          <img
                  src={Activitycheck}
                  alt="Activitycheck"
                  style={{ height: "30px", width: "30px", marginLeft: "5px", cursor: "pointer" }}
                //   style={{
                //     cursor:
                //       AedFormData[index].dni_array_list.findIndex(
                //         (it) => it.bid === batteryInfo.bid
                //       ) !== -1
                //         ? "not-allowed"
                //         : "pointer",
                //   }}
                  onClick={() => {
                    handleDNDButton(index, crrIndex, crrFormData, section_name, "battery");
                  }}
                />
          </span>
          <span>
          <img
              src={Minus}
              alt="Minus"
              height={20}
              width={30}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleRemoveMainBatteryRow(
                  index,
                  crrIndex,
                  false,
                  false,
                  section_name
                );
              }}
            />
          </span>

          <img src="" />
        </div>
      </div>

      {openSitelistModel && (
        <SiteListModel
          openSitelistModel={openSitelistModel}
          setOpenSitelistModel={setOpenSitelistModel}
          setInventoryModal={setInventoryModal}
          section_name={section_name}
          setApi={setApi}
          selectSite={selectSite}
          setSelectedSite={setSelectedSite}
        />
      )}

      {inventoryModal ? (
        <>
          <NewAedInventoryModel
            setNewFormData={setFormData}
            Bindex={crrIndex}
            // Pindex={}
            index={index}
            AedFormData={formData}
            inventoryModal={inventoryModal}
            setInventoryModal={setInventoryModal}
            contact_id={"1"}
            secName={section_name}
            inspection_by={"2"}
            api={api}
            selectSite={selectSite}
            setSelectedSite={setSelectedSite}
            crrFormData={crrFormData}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditActionsComp;
