import { useNavigate } from "react-router-dom"
import { Button } from 'react-bootstrap';

export default function PermissionDeniedPage() {

    const navigate = useNavigate()

    const deniedDivStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh',
        flexDirection: 'column'
    }

    const handleBack = () => {
        navigate(-1)
    }

    return (
        <div style={deniedDivStyle}>
            <h2 style={{ margin: 0, padding: 0 }}>
                404 Permission denied!
            </h2>
            <Button style={{ marginTop: '1%', border: 'none' }} onClick={handleBack}>
                Back
            </Button>
        </div>
    )
}