import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { getNavMenus } from "../../utils";

function SidebarLink({details, setOpen}) {
    const navigate = useNavigate();
    const {Icon,title,link} = details;
    
    return (
        <ListItem disablePadding onClick={() => {navigate(link); setOpen(false)}}>
            <ListItemButton>
                <Link to={link} className="nav-link">
                    <ListItemText primary={title} />
                </Link>
            </ListItemButton>
        </ListItem>
    )
}

export default SidebarLink