// Sidebar configs
import MailIcon from "@mui/icons-material/Mail";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import BackupTableIcon from "@mui/icons-material/BackupTable";
export const MenuLinks = [
  // {
  //   Icon: <MailIcon color={"inherit"} />,
  //   title: "New Account",
  //   link: "/new-account",
  //   onClick: () => navigate("/new-account"),
  // },
  {
    Icon: <PlaylistAddIcon color={"inherit"} />,
    title: "Accounts",
    link: "/accounts-listing",
    onClick: () => navigate("/accounts-listing"),
  },
  {
    Icon: <MailIcon color={"inherit"} />,
    title: "Instructor Calendar",
    link: "/instructor-calendar",
    onClick: () => navigate("/instructor-calendar"),
  },
  {
    Icon: <MailIcon color={"inherit"} />,
    title: "Admin Calendar",
    link: "/admin-calendar",
    onClick: () => navigate("/admin-calendar"),
  },
  // {
  //   Icon: <MailIcon color={"inherit"} />,
  //   title: "Admin New Account",
  //   link: "/admin-account",
  //   onClick: () => navigate("/admin-calendar"),
  // },
  {
    Icon: <MailIcon color={"inherit"} />,
    privillage: "site-tab",
    title: "Site Listing",
    link: "/Admin/Sites-listing",
    onClick: () => navigate("/Admin/Sites-listing"),
  },
  {
    Icon: <PlaylistAddIcon color={"inherit"} />,
    privillage: "equipment-tab",
    title: "Equipment Listing",
    link: "/Admin/Equipment-listing",
    onClick: () => navigate("/Admin/Equipment-listing"),
  },
  {
    Icon: <MailIcon color={"inherit"} />,
    privillage: "contact-tab",
    title: "Contact Listing",
    link: "/Admin/Contact-listing",
    onClick: () => navigate("/Admin/Contact-listing"),
  },
  {
    Icon: <MailIcon color={"inherit"} />,
    privillage: "notes-tab",
    title: "Note Listing",
    link: "/Admin/Note-listing",
    onClick: () => navigate("/Admin/Note-listing"),
  },
  {
    Icon: <MailIcon color={"inherit"} />,
    privillage: "support-tab",
    title: "Support Listing",
    link: "/Admin/Support-listing",
    onClick: () => navigate("/Admin/Support-listing"),
  },
];


export const UserMenuLinks = [
  {
    Icon: <MailIcon color={"inherit"} />,
    privillage: "Home",
    title: "Home",
    link: "/user-dashboard1",
    onClick: () => navigate("/user-dashboard1"),
  },
  {
    Icon: <MailIcon color={"inherit"} />,
    privillage: "site-tab",
    title: "Site Listing",
    link: "/user-dashboard/Sites",
    onClick: () => navigate("/user-dashboard/Sites"),
  },
  {
    Icon: <PlaylistAddIcon color={"inherit"} />,
    privillage: "equipment-tab",
    title: "Equipment Listing",
    link: "/user-dashboard/Equipment",
    onClick: () => navigate("/user-dashboard/Equipment"),
  },
  {
    Icon: <MailIcon color={"inherit"} />,
    privillage: "contact-tab",
    title: "Contact Listing",
    link: "/user-dashboard/Contacts",
    onClick: () => navigate("/user-dashboard/Contacts"),
  },
  {
    Icon: <MailIcon color={"inherit"} />,
    privillage: "notes-tab",
    title: "Note Listing",
    link: "/user-dashboard/Notes",
    onClick: () => navigate("/user-dashboard/Note"),
  },
  {
    Icon: <MailIcon color={"inherit"} />,
    privillage: "support-tab",
    title: "Support Listing",
    link: "/user-dashboard/Support",
    onClick: () => navigate("/user-dashboard/Support"),
  },
];

export const getNavMenus = (navigate) => {
  const navMenus = [
    {
      Icon: <MailIcon />,
      title: "New Account",
      link: "/new-account",
      onClick: () => navigate("/new-account"),
    },
    {
      Icon: <MailIcon />,
      title: "Accounts",
      link: "/accounts-listing",
      onClick: () => navigate("/accounts-listing"),
    },
    {
      Icon: <MailIcon />,
      title: "Meep Fitness",
      link: "/meep-fitness",
      onClick: () => navigate("/meep-fitness"),
    },
    {
      Icon: <MailIcon />,
      title: "traning-table",
      link: "/traning-table",
      onClick: () => navigate("/traning-table"),
    },
    {
      Icon: <MailIcon />,
      title: "info-table",
      link: "/info-table",
      onClick: () => navigate("/info-table"),
    },
    // {
    //   title: "alert-table-table",
    //   link:"/alert-table",
    //   onClick: () => navigate("/alert-table"),
    // },

    // {
    //   title: "Main Contact",
    //   link:"/new-contact",
    //   onClick: () => navigate("/new-contact"),
    // },
    // {
    //   title: "New Contacts",
    //   link:"/new-contact",
    //   onClick: () => navigate("/new-contact"),
    // },
    // {
    //   title: "Account Site Edit",
    //   link:"/account/site-edit",
    //   onClick: () => navigate("/new-contact"),
    // },
  ];

  return navMenus;
};

/* Accounts Listing */
// @ Account Details
export const AccountDetailsTab = {
  DETAILS: "Details",
  SITES: "Sites",
  CONTACTS: "Contacts",
  Equipment: "Equipment",
  TRAINING: "Training",
  INPERSON: "Inperson",
  POPS: "POPS",
  NOTES: "Notes",
  EMAILS: "Emails",
  SUPPORT: "Support",
  DOCUMENTS: "Documents",
  RFI: "RFI",

  // CALLS: "Calls",
};

export const AccountContactsTab = {
  Details: "Details",
  Classes: "Classes",
  Instructor: "Instructor",
  Notes: "Notes",
  Emails: "Emails",
  Support: "Support",
  Documents: "Documents",
  RFI: "RFI",
};

export const InpersonTab = {
  Details: "Details",
  Students: "Students",
  Certifications: "Certifications",
  Notes: "Notes",
};

export const UserInpersonTab = [
  { name: "Details" },
  { name: "Students" },
  { name: "Certifications" },
  { name: "Notes" }
];

export const AedTabs = {
  Details: "Details",
  Notes: "Notes",
  AEDChecks: "AED Checks",
  AEDServicing: "AED Servicing",
  Support: "Support",
  Documents: "Documents",
};

export const UserAedTabs = [
  { name: "Details" },
  { name: "Notes" },
  { name: "AEDChecks" },
  { name: "AEDServicing" },
  { name: "Support" },
  { name: "Documents" }
];

export const AccountSiteTab = {
  Details: "Details",
  Contacts: "Contacts",
  Equipment: "Equipment",
  Inperson: "Inperson",
  // Instructor: "Instructor",
  Notes: "Notes",
  Emails: "Emails",
  Support: "Support",
  Documents: "Documents",
  RFI: "RFI",
};

export const AccountPopTab = {
  Details: "Details",
  Documents: "Documents",
  Notes: "Notes",
};

export const UserPopTabs = [
  { name: "Details", id: "pop-details" },
  { name: "Documents", id: "pop-document-tab" },
  { name: "Notes", id: "pop-notes-tab" }
];