import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import TableSkeleton from '../../skeleton/table/TableSkeleton';
import Moment from 'react-moment';
import { DateFormate } from '../../../../helper/TblFn';
import { AED_IMG_BASE } from '../../../../helper/API';
import { FormatDateWithTime } from '../../../../helper/Common';

export default function Details({accountDetails, CoordiDataList, programDetails, httpsWeb}) {

    const navigate = useNavigate();
    const [ showLoading, setShowLoading ] = React.useState(true);

    useEffect(() => {
        setShowLoading(false);
    }, [accountDetails, CoordiDataList, httpsWeb]);

    return (
        <div className='relative'>
            {/* loading */}
            {showLoading && (
                <div className="showloading-table">
                    <TableSkeleton />
                </div>
            )}

            {/* heading */ }
            <Box className="text-left pt-3">
                <h4 className='heading'>General Information</h4>
            </Box>
            <table className="w-100">
                <tbody>
                    <tr className="">
                        <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">Main Site</th>
                        <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">Terms</th>
                        <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">Customer Type</th>
                        <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">Industry</th>
                        <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">Lead Source</th>
                        <th className=" py-1 px-2 bg-tbl-border border-t-blue">Account Status</th>
                    </tr>
                    <tr className="">
                        <td className=" py-1 px-2 border-r-blue">
                            { accountDetails?.main_site }
                        </td>
                        <td className=" py-1 px-2 border-r-blue">
                            { accountDetails?.terms }
                        </td>
                        <td className=" py-1 px-2 border-r-blue">
                            { accountDetails?.customer_type }
                        </td>
                        <td className=" py-1 px-2 border-r-blue">
                            { accountDetails?.industry }
                        </td>
                        <td className=" py-1 px-2 border-r-blue">
                            { accountDetails?.lead_source }
                        </td>
                        <td className=" py-1 px-2">
                            { accountDetails?.account_status }
                        </td>
                    </tr>

                    <tr className="">
                        <th className=" py-1 px-2 border-t-blue bg-tbl-border border-r-blue">Parent Account</th>
                        <th className=" py-1 px-2 border-t-blue bg-tbl-border border-r-blue" colSpan="2">
                            Distributor Account
                        </th>
                        <th className=" py-1 px-2 border-t-blue bg-tbl-border" colSpan="3">
                            Product Interest
                        </th>
                    </tr>

                    <tr className="">
                        <td className=" py-1 px-2 border-r-blue border-b-blue">
                            {accountDetails?.parent_account_id ? <>
                                <Link className='link' to={'/account-details/' + accountDetails?.parent_account_id}>{ accountDetails?.parent_account }</Link>
                            </> : ''}
                        </td>
                        <td className=" py-1 px-2 border-r-blue border-b-blue" colSpan="2">
                            {accountDetails?.distributor_account_id ? <>
                                <Link className='link' to={'/account-details/' + accountDetails?.distributor_account_id}>{ accountDetails?.distributor_account }</Link>
                            </> : ''}
                        </td>
                        <td className=" py-1 px-2 border-b-blue" colSpan="3">
                            { accountDetails?.product_interest?.join(", ") }
                        </td>
                    </tr>

                    <tr className="">
                        <th className=" py-1 px-2 bg-tbl-border border-r-blue" colSpan="3">
                            Important Note
                        </th>
                        <th className=" py-1 px-2 bg-tbl-border" colSpan="3">
                            Website
                        </th>
                        </tr>
                        <tr className="">
                        <td className=" py-1 px-2 border-b-blue border-r-blue" colSpan="3">
                            { accountDetails?.inportant_notes }
                        </td>
                        <td className=" py-1 px-2 border-b-blue" colSpan="3">
                            {accountDetails?.website != '' && accountDetails?.website != null && accountDetails?.website != '-' ? <>
                            <a href={ !httpsWeb ? 'https://' + accountDetails?.website : accountDetails?.website } target='_blank' >
                                { accountDetails?.website }
                            </a>
                            </> : ''}
                        </td>
                    </tr>
                </tbody>
            </table>

            {/* Program Details */ }
            <Box className="text-left pt-3 ">
                <h4 className='heading'>Program Details</h4>
            </Box>

            <table className="w-100 border-b-blue">
                <tbody>
                    <tr className="">
                        <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">Active Sites</th>
                        <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">AEDS</th>
                        <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">Equipment</th>
                        <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">CPR Trained</th>
                        <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">AED Check length</th>
                        <th className=" py-1 px-2 bg-tbl-border border-t-blue">Extra Fields</th>
                    </tr>
                    <tr className="">
                        <td className=" py-1 px-2 border-r-blue">
                            {programDetails?.activeSites}
                        </td>
                        <td className=" py-1 px-2 border-r-blue">
                            {programDetails?.aeds}
                        </td>
                        <td className=" py-1 px-2 border-r-blue">
                            
                        </td>
                        <td className=" py-1 px-2 border-r-blue">
                            
                        </td>
                        <td className=" py-1 px-2 border-r-blue">
                            {accountDetails?.aed_check_length}
                        </td>
                        <td className=" py-1 px-2">
                            {accountDetails?.extra_field1}{accountDetails?.extra_field2 ? ', ' + accountDetails?.extra_field2 : ''}
                        </td>
                    </tr>
                </tbody>
            </table>

            {/* AED Units Owned */ }
            <Box className="text-left pt-3 ">
                <h4 className='heading'>AED Units Owned</h4>
            </Box>

            <table className="w-100 border-b-blue last-r-border-none">
                <tbody>
                {programDetails?.aed_units?.length > 0 ? <>
                    <tr className="">
                        {programDetails?.aed_units?.map((aed, index) => (
                            <th scope='col' width="20%" className="text-center py-1 px-2 bg-tbl-border border-t-blue border-r-blue" key={index}>    {aed?.brandName} {aed?.modalName}
                            </th>
                        ))}
                        {programDetails?.aed_units?.length < 5 && (
                            <>
                                <th scope='col' width="20%" className="text-center py-1 px-2 bg-tbl-border border-t-blue border-r-blue"></th>
                                <th scope='col' width="20%" className="text-center py-1 px-2 bg-tbl-border border-t-blue border-r-blue"></th>
                                <th scope='col' width="20%" className="text-center py-1 px-2 bg-tbl-border border-t-blue"></th>
                            </>
                        )}
                    </tr>
                    <tr className="">
                        {programDetails?.aed_units?.map((aed, index) => (
                            <td className=" py-1 px-2 border-r-blue" key={index}>
                                <div className="text-center">
                                    <img src={ AED_IMG_BASE + aed?.modalImage} alt={aed?.modalName} style={{maxWidth: '100px', marginTop: '20px'}} />
                                    <p className='mt-3'>Own: {aed?.own}</p>
                                </div>
                            </td>
                        ))}

                        {programDetails?.aed_units?.length < 5 && (
                            <>
                                <td className=" py-1 px-2 border-r-blue"></td>
                                <td className=" py-1 px-2 border-r-blue"></td>
                                <td className=" py-1 px-2"></td>
                            </>
                        )}
                    </tr>
                </> : <>
                    <tr className="">
                        <td colSpan={5} className='text-center py-1 px-2 bg-tbl-border border-t-blue'>No AED units found</td>
                    </tr>
                </>}
                </tbody>
            </table>

            {/* Coordinator Information */ }
            <Box>
              <h4 className='heading mt-3'>Coordinator Information</h4>
              <table className="w-100 last-table-border-hide">
                <tbody>

                  <tr className="">
                    { CoordiDataList.map((CoorD, index) => (
                      <td className="py-1 px-2 bg-tbl-border border-t-blue border-r-blue" key={index}>{ CoorD.title }</td>
                    )) }
                  </tr>
                  <tr>
                    { CoordiDataList.map((CoorD, i1) => (
                      <td className="py-1 px-2 border-r-blue" key={i1}> P: <span onClick={() => { navigate('/account/' + accountId + '/contact-details/' + CoorD?.arr?.[ 0 ]?.contact_id || CoorD?.arr?.[ 1 ]?.contact_id, {
                        state: {
                          siteTitle: "Contact : " + CoorD?.arr?.[ 0 ]?.primary || CoorD?.arr?.[ 1 ]?.primary,
                          editUrl: "/account/contact-details-edit/" + CoorD?.arr?.[ 0 ]?.contact_id || CoorD?.arr?.[ 1 ]?.contact_id,
                          deleteUrl: "/account/contact-details-edit/" + CoorD?.arr?.[ 0 ]?.contact_id || CoorD?.arr?.[ 1 ]?.contact_id
                        }
                      })}} className="link"> { CoorD?.arr?.[ 0 ]?.primary || CoorD?.arr?.[ 1 ]?.primary }</span>
                      </td>
                    )) }
                  </tr>

                  <tr>
                    { CoordiDataList.map((CoorD, i1) => (
                      <td className="py-1 px-2 border-b-blue border-r-blue" key={i1}> B:
                      <span onClick={() => { navigate('/account/' + accountId + '/contact-details/' + CoorD?.arr?.[ 0 ]?.contact_id || CoorD?.arr?.[ 1 ]?.contact_id, {
                        state: {
                          siteTitle: "Contact : " + CoorD?.arr?.[ 0 ]?.backup || CoorD?.arr?.[ 1 ]?.backup,
                          editUrl: "/account/contact-details-edit/" + CoorD?.arr?.[ 0 ]?.contact_id || CoorD?.arr?.[ 1 ]?.contact_id,
                          deleteUrl: "/account/contact-details-edit/" + CoorD?.arr?.[ 0 ]?.contact_id || CoorD?.arr?.[ 1 ]?.contact_id
                        }
                      })}} className="link"> { CoorD?.arr?.[ 0 ]?.backup || CoorD?.arr?.[ 1 ]?.backup }</span>
                       </td>
                    )) }
                  </tr>


                </tbody>
              </table>
            </Box>
            <Container style={ { marginTop: "96px" } }>
              <Box className="d-flex justify-content-evenly ">
                <span>Created Date: {accountDetails?.created_date ? FormatDateWithTime(accountDetails?.created_date)  : ''}</span>
                <span>Created By: { accountDetails?.created_by }</span>
                <span>Modified Date: {accountDetails?.modified_date ? FormatDateWithTime(accountDetails?.modified_date) : '' } </span>
                <span>Modified By: { accountDetails?.modifiedBy?.account_name }</span>
                <span>Last Touch Date: 11-14-2020 12:00:00</span>
              </Box>
            </Container>
        </div>
    )
}
