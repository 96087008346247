import React, { useState } from "react";
import { NewModiFicationGroupIcon } from "../../../../helper/Icons";
import SiteListModel from "../../../modals/AED/SiteListModel";
// import AEDInventoryModal from '../../../../pages/accounts/AedMain/AEDInventoryModal';
import NewAedInventoryModel from "../../../modals/AED/NewAedInventoryModel";
import CustomToggleButton from "../../../common/toggleSwitch/CustomToggleButton";
// import QuestionMark from "../../public/QuestionMark.png";
import QuestionMark from "../../../../img/QuestionMark.png";
const ActionsComp = ({
  index,
  crrIndex,
  formData,
  setFormData,
  section_name,
  BatteryList,
  handleInputChange,
  is_readonly = 0,
  crrFormData,
  unKnownToggleKey,
}) => {
  const [openSitelistModel, setOpenSitelistModel] = useState(false);
  const [inventoryModal, setInventoryModal] = useState(false);
  const [selectSite, setSelectedSite] = useState("");
  const [api, setApi] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handle_unknow_checkbox = (e, keyname, toogleKeyName) => {
    const oldData = { ...formData };

    const save_obj = {
      battery_type_id: e.target.checked ? "unknown" : "",
      battery_expiration: e.target.checked ? "unknown" : "",
      battery_lot: e.target.checked ? "unknown" : "",
      battery_uid: e.target.checked ? "unknown" : "",
      v9_install: e.target.checked ? "unknown" : "",
      install_before_date: e.target.checked ? "unknown" : "",
      date_installed: e.target.checked ? "unknown" : "",
      manufactured_date: e.target.checked ? "unknown" : "",
    };

    if (oldData[keyname]) {
      let newArr = [];
      for (let ari = 0; ari < oldData[keyname].length; ari++) {
        newArr.push(save_obj);
      }
      oldData[keyname] = newArr;
      oldData[toogleKeyName] = e.target.checked;
      setFormData(oldData);
    }
  };

  const handleCheckboxClick = () => {
    setIsChecked((prevState) => !prevState);
    const e = { target: { checked: !isChecked } };
    handle_unknow_checkbox(e, section_name, unKnownToggleKey);
  };

  // BatteryList
  return (
    <>
      <div className="col form-group" style={{ maxWidth: "100px" }}>
        <label htmlFor="">Actions</label>
        <div>
          <span
            onClick={isChecked == true ? undefined  : setOpenSitelistModel}
            style={{ cursor: "pointer", marginRight: "2px" }}
            disabled={isChecked == true}
          >
            {" "}
            <NewModiFicationGroupIcon />{" "}
          </span>
          <span>
            <img
              src={QuestionMark}
              alt=""
              onClick={handleCheckboxClick}
              style={{ height: "20px", width: "20px", marginLeft: "5px" }}
            />
          </span>

          <img src="" />
        </div>
      </div>

      {openSitelistModel && (
        <SiteListModel
          openSitelistModel={openSitelistModel}
          setOpenSitelistModel={setOpenSitelistModel}
          setInventoryModal={setInventoryModal}
          section_name={section_name}
          setApi={setApi}
          selectSite={selectSite}
          setSelectedSite={setSelectedSite}
        />
      )}

      {inventoryModal ? (
        <>
          <NewAedInventoryModel
            setNewFormData={setFormData}
            Bindex={crrIndex}
            // Pindex={}
            index={index}
            AedFormData={formData}
            inventoryModal={inventoryModal}
            setInventoryModal={setInventoryModal}
            contact_id={"1"}
            secName={section_name}
            inspection_by={"2"}
            api={api}
            selectSite={selectSite}
            setSelectedSite={setSelectedSite}
            crrFormData={crrFormData}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ActionsComp;
